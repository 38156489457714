import React from 'react'
import { graphql } from 'gatsby'
import styled from '@xstyled/styled-components'
import Layout from '@organisms/Layout'
import Hero from '@organisms/Ventures/Hero'
import Section from '@organisms/Ventures/Section'

import Cta from '@molecules/Cta'

import idgen from '@utils/idgen'

const Ventures = ({ data }) => {
  const { blocks, meta } = data.labs.nodes[0].frontmatter

  return (
    <Layout
      hamburgerMenu
      title={meta.title}
      description={meta.description}
      buttonColor="dark"
    >
      <S.Main>
        {blocks.map((item) => {
          switch (item.type) {
            case 'hero':
              return <Hero data={item} key={idgen()} />
            case 'section':
              return <Section data={item} key={idgen()} />
            case 'cta':
              return <Cta data={item} key={idgen()} />
            default:
              return null
          }
        })}
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox``

export default Ventures

// Set here the ID of the home page.
export const pageQuery = graphql`
  query VenturesPage {
    labs: allMarkdownRemark(filter: { frontmatter: { slug: { eq: "labs" } } }) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
  }
`
