import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'

import { TitleSection, TitleContent } from '@atoms/Typography'
import ImageContent from '@molecules/ImageContent'
import { Container } from '@atoms/Grid'
import idgen from '@utils/idgen'

const Section = ({ data }) => {
  const { sectionTitle, items } = data
  const copyImages = items.filter((item) => item.type === 'copyImage')

  return (
    <S.Section mb={{ xs: 50, md: 140 }}>
      {sectionTitle && <TitleSection>{sectionTitle}</TitleSection>}
      {copyImages.length > 0 &&
        copyImages.map((item) => (
          <ImageContent
            key={idgen()}
            inverse={item.inverse}
            marginBottom={{ xs: 50, md: 200 }}
            data={item}
          />
        ))}
    </S.Section>
  )
}

export default Section

const S = {}

S.Section = styled(Container)``
