import React from 'react'
import Markdown from 'react-markdown'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import HTMLContent from '@atoms/HTMLContent'
import { Container, Row, Col } from '@atoms/Grid'
import { ButtonLinkExternal } from '@atoms/Button'

const Hero = ({ data, ...props }) => {
  const { title, paragraphs, button, buttonDesktop, buttonLink, image } = data
  return (
    <S.Wrapper {...props}>
      <S.Container>
        <Row>
          <Col col={{ xs: 1, xl: 6 / 12 }}>
            <S.Title
              as="h1"
              pt={{ xs: 60, md: 84 }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <S.Paragraphs>{paragraphs}</S.Paragraphs>
            {buttonDesktop && button && (
              <ButtonLinkExternal
                variant="dark"
                href={buttonLink}
                target="_blank"
              >
                <x.span display={{ xs: 'none', md: 'block' }}>
                  {buttonDesktop}
                </x.span>
                <x.span display={{ xs: 'block', md: 'none' }}>{button}</x.span>
              </ButtonLinkExternal>
            )}
          </Col>
          {image && (
            <Col
              col={{ xs: 1, xl: 5 / 12 }}
              mt={{ xs: 84, xl: 0 }}
              ml={{ xl: `${(1 / 12) * 100}%` }}
            >
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={title}
              />
            </Col>
          )}
        </Row>
      </S.Container>
    </S.Wrapper>
  )
}

export default Hero

const S = {}

S.Wrapper = styled.section`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      padding-top: 165;
      padding-bottom: 50;
      width: 100%;
    `,
    md: css`
      padding-top: 130;
      flex-direction: row;
      padding-bottom: 140;
      align-items: flex-start;
    `,
    lg: css`
      padding-top: 200;
      flex-direction: row;
      padding-bottom: 140;
      align-items: flex-start;
    `,
  })}
`

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
    `,
    md: css`
      flex-direction: row;
      align-items: flex-start;
    `,
  })}
`

S.Content = styled(HTMLContent)`
  width: 100%;
  max-width: 588;
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    md: css`
      font-size: 24;
    `,
    lg: css`
      margin-right: 102;
    `,
  })}
`

S.Title = styled.divBox`
  font-weight: ${th('fontWeights.black')};
  ${breakpoints({
    xs: css`
      font-size: 32;
      margin-bottom: 15;
      line-height: 110%;
      letter-spacing: -0.04em;
    `,
    md: css`
      font-size: 72;
      margin-bottom: 30;

      line-height: 110%;
      letter-spacing: -0.04em;
    `,
  })}
`

S.Paragraphs = styled(Markdown)`
  ${breakpoints({
    xs: css`
      margin-bottom: 30;
    `,
    md: css`
      margin-bottom: 31;
      font-size: 24;
    `,
  })}
`
